<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">验货报告（鞋类）</el-col>
    </el-row>
    <el-form ref="approvalForm" :model="approvalForm" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="买家" :class="$i18n.locale" prop="buyer">
            <el-input v-model="approvalForm.buyer" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同号" :class="$i18n.locale" prop="contractCode">
            <el-input v-model="approvalForm.contractCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购订单号" :class="$i18n.locale" prop="approvalCode">
            <el-input v-model="approvalForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商" :class="$i18n.locale" prop="relationCode">
            <el-input v-model="approvalForm.vendorName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="客人货号" prop="relationCode">
            <el-input v-model="approvalForm.style" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="订单数量" :class="$i18n.locale" prop="relationCode">
            <el-input v-model="approvalForm.pairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="检验数量" :class="$i18n.locale" prop="relationCode">
            <el-input v-model="approvalForm.checkPairs" :placeholder="$t('page.inputPlaceholder')" oninput="value=value.replace(/[^\d]/g,'')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="质检状态" :class="$i18n.locale" prop="status">
            <!-- <el-select v-model="approvalForm.qualityStatus" value-key="id" multiple clearable filterable>
              <el-option v-for="item in _getAllCommodityDict('QUALITY_STATUS')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select> -->
            <el-select v-model="approvalForm.qualityStatus" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option label="待质检" value="0" />
              <el-option label="通过" value="1" />
              <el-option label="未通过" value="2" />

            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="A:检验" :class="$i18n.locale" prop="approvalCode">
            <span style="display:flex"><el-input v-model="approvalForm.checkQuantity" :placeholder="$t('page.inputPlaceholder')" oninput="value=value.replace(/[^\d]/g,'')" />
              <span style="display: block;width: 50px;">双/箱</span></span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="B:总箱数" :class="$i18n.locale" prop="relationCode">
            <span style="display:flex"><el-input v-model="approvalForm.totalQuantity" :placeholder="$t('page.inputPlaceholder')" oninput="value=value.replace(/[^\d]/g,'')" /><span style="display: block;width: 50px;">箱</span></span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="C:抽样流水号" :class="$i18n.locale" prop="approvalCode">
            <el-input v-model="approvalForm.serialNumber" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row class="row-flexed" :gutter="20">
        <el-col :span="10">
          <!-- <el-form-item v-if="qualityStatus === 1 ||qualityStatus === '1'" :class="$i18n.locale" label="质检图片" prop="brcfilelist">
            <el-upload
              :disabled="true"
              :action="action"
              :headers="uploadHeaders"
              list-type="picture-card"
              :limit="limitCount1"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview"
              :before-remove="beforeRemoved"
              :on-success="brchandleSuccess"
              :on-remove="brchandleRemove"
              :on-change="brchandleFileChange"
              :file-list="brcfilelist"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="imgdialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item> -->

          <!-- <el-form-item v-else :class="$i18n.locale" label="质检图片" prop="brcfilelist"> -->
          <el-form-item :class="$i18n.locale" label="质检图片" prop="brcfilelist">
            <el-upload
              :class="{hide:hideUpload1}"
              :action="action"
              :headers="uploadHeaders"
              list-type="picture-card"
              :limit="limitCount1"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview"
              :before-remove="beforeRemoved"
              :on-success="brchandleSuccess"
              :on-remove="brchandleRemove"
              :on-change="brchandleFileChange"
              :file-list="brcfilelist"
            >
              <!-- :file-list="brcfilelist" -->

              <!-- <img v-if="approvalForm.images && approvalForm.images !== null" width="100%" height="100%" :src="approvalForm.images" alt=""> -->
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="imgdialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="质检文件" prop="lfoafilelist">
            <el-upload
              ref=""
              :on-preview="handleFilesCardPreview"
              :class="{hide:hideUpload2}"
              :headers="uploadHeaders"
              :action="action"
              class="upload-demo"
              multiple
              :before-upload="beforeAvatarUpload"
              :on-change="lfoahandleFileChange"
              :on-remove="lfoahandleRemove"
              :on-success="lfoahandleSuccess"
              :file-list="lfoafilelist"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-dialog :visible.sync="filesDialogVisible">
              <button><a :href="dialogFileUrl" target="_blank">下载文件预览</a></button>
            </el-dialog>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="24">
          <el-form-item label="总结" :class="$i18n.locale" prop="approvalCode">
            <el-input v-model="approvalForm.checkSummary" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="14">
          <el-form-item label="" prop="approvalCode">
            <el-radio v-model="approvalForm.whetherAccept" label="1">可接受</el-radio>
            <el-radio v-model="approvalForm.whetherAccept" label="2">翻箱</el-radio>
            <el-radio v-model="approvalForm.whetherAccept" label="3">重验</el-radio>
            <el-radio v-model="approvalForm.whetherAccept" label="4">拒绝接受</el-radio>
            <el-radio v-model="approvalForm.whetherAccept" label="5">保函出货</el-radio>

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产前小批量测试" prop="approvalCode">
            <el-radio v-model="approvalForm.smallTest" label="0">通过</el-radio>
            <el-radio v-model="approvalForm.smallTest" label="2">未通过</el-radio>

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="大货测试" prop="approvalCode">
            <!-- <el-radio-group v-model="approvalForm.bigTest">
              <el-radio :label="0">通过</el-radio>
              <el-radio :label="2">未通过</el-radio>
            </el-radio-group> -->
            <el-radio v-model="approvalForm.bigTest" label="0">通过</el-radio>
            <el-radio v-model="approvalForm.bigTest" label="2">未通过</el-radio>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <el-row type="flex" justify="center">
      <el-button v-if="shows" type="primary" @click="dialogVisible = true">提交</el-button>
      <el-button v-if="addshows" :loading="Loading" type="primary" @click="handleAddFiles">追加提交</el-button>
    </el-row>
    <el-dialog
      :visible.sync="dialogVisible"
      title="提示"
      width="30%"
    >
      <span>确认要提交质检单么</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="saveloading" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { qualityAddFile, qualitydetail, qualitysubmit } from '@/api/scm-api'
// import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  // mixins: [commodityInfoDict],
  data() {
    return {
      Loading: false,
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      hideUpload1: false,
      hideUpload2: false,
      limitCount1: 30,
      limitCount2: 30,
      dialogVisible: false,
      imgdialogVisible: false,
      filesDialogVisible: false,
      dialogImageUrl: '',
      dialogFileUrl: '',
      brcfilelist: [], //  上传用图片
      lfoafilelist: [], //  上传用文件
      action: process.env.VUE_APP_SCM_API + 'scm-purchase/basic/uploadFile',
      shows: true,
      addshows: false,
      fordisabled: false, // 禁用文本框
      approvalForm: {
        qualityStatus: '',
        whetherAccept: '',
        smallTest: '',
        bigTest: '',
        images: [],
        files: []
      },
      saveloading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      rules: {

      },
      qualityStatus: ''
    }
  },
  mounted() {
    this.qualityStatus = this.$route.query.qualityStatus
    console.log(this.qualityStatus)
    this._qualitydetail()
  },
  methods: {

    // 输入框字段回显
    async _qualitydetail(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await qualitydetail(id)
        // 点击列表按钮进入详情页时数据回显
        this.approvalForm = datas
        this.approvalForm.qualityStatus = String(datas.qualityStatus)
        this.approvalForm.whetherAccept = datas.whetherAccept ? String(datas.whetherAccept) : '1'
        this.approvalForm.smallTest = datas.smallTest ? String(datas.smallTest) : '0'
        this.approvalForm.bigTest = datas.bigTest ? String(datas.bigTest) : '0'
        if (datas.images) { // 后台返回的文件链接
          const a = datas.images.split(',')
          this.approvalForm.images = a
          console.log(this.approvalForm.images)
          if (a.length > 0) {
            console.log(a)
            a.forEach(item => {
              var obj = {}
              const index = item.lastIndexOf('\/')
              const fileName = item.substring(index + 1, item.length) // 最后的文件名截取出来
              this.$set(obj, 'name', fileName)
              this.$set(obj, 'url', item) // 修改files里面的结构（name,url）
              this.brcfilelist.push(obj)
            })
            console.log(this.brcfilelist)
          }
        }
        if (datas.files) { // 后台返回的文件链接
          const a = datas.files.split(',')
          this.approvalForm.files = a
          if (a.length > 0) {
            a.forEach(item => {
              var obj = {}
              const index = item.lastIndexOf('\/')
              const fileName = item.substring(index + 1, item.length) // 最后的文件名截取出来
              this.$set(obj, 'name', fileName)
              this.$set(obj, 'url', item) // 修改files里面的结构（name,url）
              this.lfoafilelist.push(obj)
            })
          }
        }
        if (this.$route.query.qualityStatus === 1 || this.$route.query.qualityStatus === '1') {
          this.shows = false
        }
        if (this.$route.query.qualityStatus === 1 || this.$route.query.qualityStatus === '1') {
          this.addshows = true
        }
      }
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file, fileList) {
      if (file.url) {
        this.dialogImageUrl = file.url
      } else {
        this.dialogImageUrl = file.response.datas
      }
      this.imgdialogVisible = true
    },

    // 点击已上传的文件时的方法 看大图
    handleFilesCardPreview(file) {
      // 将上传成功的图片放到提交给后台的参数中
      if (file.url) {
        this.dialogFileUrl = file.url
      } else {
        this.dialogFileUrl = file.response.datas
      }
      this.filesDialogVisible = true
    },
    // 上三证合一传图片成功时的方法
    brchandleSuccess(files, filelist) {
      this.approvalForm.images === null ? this.approvalForm.images = [] : ''
      this.approvalForm.images === '' ? this.approvalForm.images = [] : ''
      this.approvalForm.images.push(files.datas)
    },
    // 上传文件成功时的方法
    lfoahandleSuccess(files, filelist) {
      console.log(this.approvalForm)
      this.approvalForm.files === null ? this.approvalForm.files = [] : ''
      this.approvalForm.files === '' ? this.approvalForm.files = [] : ''
      this.approvalForm.files.push(files.datas)
    },
    // 移除图片之前的方法
    beforeRemoved(file, filelist) {
      return this.$confirm(`确定移除当前图片 ${file.name}吗？`)
    },
    beforeAvatarUpload(file) {
      console.log(file)
      const isLt20M = file.size / 1024 / 1024 < 10
      if (!isLt20M) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '上传文件大小不得超过10M'
        })
      }
      return isLt20M
    },
    brchandleRemove(file, fileList) {
      this.hideUpload1 = fileList.length >= this.limitCount1
      if (file.status === 'success') {
        this.approvalForm.images = ''
      }
    },
    // 图片状态改变时的钩子，将加号隐藏
    brchandleFileChange(file, fileList) {
      // const fileName = file.name
      // const regex = /(.jpg|.png|.gig|.jpeg|.bmp)/
      // if (regex.test(fileName.toLowerCase())) {
      //   this.dialogImageUrl = URL.createObjectURL(file.raw)
      // }
      // console.log(this.dialogImageUrl)
      this.hideUpload1 = fileList.length >= this.limitCount1
    },
    lfoahandleRemove(file, fileList) {
      let url = ''
      if (file.status === 'success') {
        if (file.url) {
          url = file.url
        } else if (file.response && file.response.datas) {
          url = file.response.datas
        }
        const index = this.approvalForm.files.indexOf(url)
        index !== -1 ? this.approvalForm.files.splice(index, 1) : ''
      }
    },
    lfoahandleFileChange(file, fileList) {
      console.log(this.approvalForm.files)
    },
    // 追加图片和文件
    handleAddFiles() {
      const data = {}
      data.purchaseOrderCode = this.approvalForm.purchaseOrderCode
      data.checkPairs = this.approvalForm.checkPairs
      data.checkQuantity = this.approvalForm.checkQuantity
      data.totalQuantity = this.approvalForm.totalQuantity
      data.serialNumber = this.approvalForm.serialNumber
      data.checkSummary = this.approvalForm.checkSummary
      data.images = this.approvalForm.images ? this.approvalForm.images.join() : ''
      data.files = this.approvalForm.files ? this.approvalForm.files.join() : ''
      this._qualityAddFile(data)
    },
    // 调用追加图片文件
    async _qualityAddFile(vo) {
      try {
        this.Loading = true
        const { code, msg } = await qualityAddFile(vo)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
      }
    },
    // 提交质检报告
    save() {
      if ((this.approvalForm.images === '' || this.approvalForm.images === null) && (this.approvalForm.files === '' || this.approvalForm.files === null)) {
        this.$confirm('请先选择图片或文件至少一项提交', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.dialogVisible = false
        return false
      }
      let data = {}
      data = JSON.parse(JSON.stringify(this.approvalForm))
      data.whetherAccept = Number(data.whetherAccept)
      data.smallTest = Number(data.smallTest)
      data.bigTest = Number(data.bigTest)
      data.images = this.approvalForm.images ? this.approvalForm.images.join() : ''
      data.files = this.approvalForm.files ? this.approvalForm.files.join() : ''
      this._qualitysubmit(data)
    },
    async _qualitysubmit(data) {
      try {
        this.saveloading = true
        const { code, msg } = await qualitysubmit(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.dialogVisible = false
          this.saveloading = false
        }
      } finally {
        this.dialogVisible = false
        this.saveloading = false
      }
    }

  }
}
</script>
<style scope>
.hide .el-upload--picture-card {
  display: none;
}
</style>
